@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Kanit:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Caveat:wght@500&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Kanit:wght@500;600&display=swap');


.v-con-logo {
    font-family: 'Heebo', sans-serif;
    padding: 3px;
}

.v-con-logo-con {
    border-radius: 1.4rem;
}

.v-con-logo-con:hover {
    background-color: rgb(161, 76, 76);
    border-radius: 1.4rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}


.v-store-t {
    font-family: 'Caveat', cursive;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(128, 98, 98);
    opacity: 1;
    /* Firefox */
    font-weight: 500;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(255, 87, 87);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
}

.sign-in-btn {
    border-radius: 1.4rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    color: red;
}

.sign-in-btn:hover {
    background-color: rgb(161, 76, 76);
    border-radius: 1.4rem;
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.menu-link {
    max-width: max-content;
    font-size: 1rem;
    position: relative;
    cursor: pointer;
}

.menu-link::after {
    content: "";
    position: absolute;
    bottom: -4px;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: red;
    transition: 0.3s;
    transition-timing-function: ease-in-out;
    transform: scaleX(0);
    border-radius: 0.2rem;
}

.menu-link:hover::after {
    transform: scaleX(1);
}

.nav-container,
.footer,
.contact {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.nav-container {
    position: fixed;
    top: 0px;
}

.shop-reg {
    display: none;
    transition: 0.6s;
}


.shop-reg-con:hover>.shop-reg {
    display: flex;
    transition: 0.4s;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    bottom: 50%;
    text-shadow: 1.2px 2px 1.2px black;
}

h1 {
    text-shadow: 2px 2px 2px black;

}

.car-button {
    width: 7.5rem;
    height: 2.6rem;
    background-color: red;
    color: white;
    border: 1.2px solid red;
    margin-top: 0.3rem;
}

.car-button:hover {
    background-color: transparent;
    color: red;
    transition: 0.4s;
}

.car-button1 {
    width: 7.5rem;
    height: 2.6rem;
    background-color: transparent;
    color: red;
    border: 1.2px solid red;
    margin-top: 0.3rem;
    margin-right: 7px;
}

.car-button1:hover {
    background-color: red;
    color: white;
    transition: 0.4s;
}

.car-button2 {
    width: 11rem;
    height: 2.6rem;
    background-color: transparent;
    color: red;
    border: 1.2px solid red;
    margin-top: 0.3rem;
    margin-right: 7px;
    background-color: white;
}


.a-box {
    display: inline-block;
    width: 240px;
    text-align: center;
}

.img-container {
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
    display: inline-block;
}

.img-container img {
    transform: skew(0deg, -13deg);

    margin: -35px 0px 0px -70px;
}

.inner-skew {
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
    padding: 0px;
    font-size: 0px;
    margin:10px 0px 0px 0px;
    background: hsl(0, 5%, 77%);
    width: 100%;
}

.text-shadow, .about-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.text-container {
    padding: 120px 10px 10px 20px;
    border-radius: 5px;
    margin: -140px 0px 0px 0px;
    line-height: 19px;
    font-size: 14px;
}

.text-container h3 {
    margin: 20px 0px 10px 0px;
    color: #04bcff;
    font-size: 18px;
}

.about-con {
    background-color: #f4f4f4;
}


@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext");


.card2 {
    border-radius: 10px;
    filter: drop-shadow(0 5px 10px 0 #ffffff);
    width: 770px;
    height: 360px;
    background-color: #ffffff;
    padding: 20px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: 0.6s ease-in;
}

.card2::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -15px;
    right: -15px;
    background: red;
    height: 390px;
    width: 25px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.card2:hover::before {
    transition-delay: 0.2s;

    transform: scale(100);
}

.card2:hover {
    color: #ffffff;

}

.hndc-border {
    border-bottom: 2px solid red;
}

.card2:hover>.hndc-border {
    border-bottom: 2px solid white;
}

.card2 p {
    padding: 10px 0;
}

.galler-image:hover {
    opacity: 0.7;
}


.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 50%;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 50%;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

 
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Caveat:wght@500&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Jost:wght@300&family=Kanit:wght@500;600&family=Montserrat:wght@300&family=Poppins:wght@300&family=Quicksand:wght@300&family=Ysabeau+Office:ital,wght@0,300;1,200&display=swap');

h1,h2,h3,h4,h5,h6 {
    letter-spacing: 0.23rem;
    font-size: 23;
    font-family: 'Jost', sans-serif;
}

p,.p1 {
    font-family: 'Jost', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
}

.typo {
    font-family: 'Jost', sans-serif;
}

.p11 {
    font-size: 24px;
    line-height: 1.75;
    margin-top: 6.3rem;
    font-weight: 500;
  }

  figcaption{
    opacity: 0;
  }

 @media (max-width : 500px){
    .p11 {
        font-size: 15px;
        line-height: 1.75;
        margin-top: 3.2rem;
        font-weight: 500;
      } 

      figcaption{
        opacity: 1;
      }
 }
 