$base-duration: 500ms;

// Colors
$color-1: #2c3e50;
$color-2: white;
$color-3: #2ecc71;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}


figure {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2px;
  transition: all 0.4s cubic-bezier(.17, .4, .17, 1);
  z-index: 100;



  &:hover {
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
    z-index: 1000;

    h1 {
      opacity: 1;
      transform: scale(1);
    }

    img {
      transform: scale(1.19);
    }

    figcaption {
      bottom: 0;
      display: block;
      opacity: 1;
    }
  }

  h1 {
    position: absolute;
    top: 37px;
    left: 40px;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 60px;
    font-weight: 100;
    line-height: 1;
    opacity: 1;
    transform: scale(1);
    transition: 1.3s ease;
    z-index: 999;
  }

  img {
    height: 100%;
    transition: 0.5s;
  }

 

  figcaption {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -9%;
    padding: 5;
    z-index: 2;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 30px;
    opacity: 0;
    background-color: rgba(black, 0.34);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    color: white;
    line-height: 1;
    transition: 0.5s;

    h3 {
      margin: 0 0 20px;
      padding: 0;
    }


    button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0;
      padding: 10px 30px;
      background-color: $color-2;
      border: none;
      border-radius: 0.1rem;
      color: red;
      height: 2.4rem;
      font-size: 14px;
      cursor: pointer;
      margin-top: -39px;
    }
  }
}

.figc {
  @media only screen and (max-width: 600px) {
    opacity: 1;
  }
}
 